// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-community-guidelines-js": () => import("./../../../src/pages/community-guidelines.js" /* webpackChunkName: "component---src-pages-community-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-whispr-index-js": () => import("./../../../src/pages/whispr/index.js" /* webpackChunkName: "component---src-pages-whispr-index-js" */),
  "component---src-pages-whispr-privacy-policy-js": () => import("./../../../src/pages/whispr/privacy-policy.js" /* webpackChunkName: "component---src-pages-whispr-privacy-policy-js" */),
  "component---src-pages-whispr-reply-post-js": () => import("./../../../src/pages/whispr/reply-post.js" /* webpackChunkName: "component---src-pages-whispr-reply-post-js" */),
  "component---src-pages-whispr-terms-js": () => import("./../../../src/pages/whispr/terms.js" /* webpackChunkName: "component---src-pages-whispr-terms-js" */)
}

